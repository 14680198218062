var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DAV-toast DAV-toast--success"
  }, [_c('Dimmed', {
    on: {
      "passEmit": _vm.onHide
    }
  }), _c('div', {
    staticClass: "DAV-toast__wrap"
  }, [_c('button', {
    staticClass: "DAV-toast--close",
    on: {
      "click": _vm.onHide
    }
  }, [_vm._v("닫기")]), _c('p', {
    staticClass: "DAV-toast__text",
    domProps: {
      "innerHTML": _vm._s(_vm.getAlert.message)
    }
  })])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }