<template>
  <div class="DAV-toast DAV-toast--success">
    <Dimmed @passEmit="onHide"></Dimmed>
    <div class="DAV-toast__wrap">
      <button class="DAV-toast--close" @click="onHide">닫기</button>
      <p class="DAV-toast__text" v-html="getAlert.message"></p>
    </div>
  </div>
</template>

<script>
import {createNamespacedHelpers} from "vuex";

const ModuleAlert = createNamespacedHelpers("ModuleAlert");

export default {
  data() {
    return {};
  },
  computed: {
    ...ModuleAlert.mapGetters(["getAlert"])
  },
  methods: {
    // 취소
    onHide() {
      this.setAlertVisible(false);
      this.$EventBus.$emit("alertCancel", this.getAlert.event);
    },

    ...ModuleAlert.mapMutations(["setAlertVisible"])
  },
  components: {
    Dimmed: () => import("@/components/basis/dimmed.vue")
  }
};
</script>

<style lang="scss" scoped>
.DAV-toast {
  z-index: 10000004;

  .DAV-dimmed {
    z-index: 10000003;
  }

  &__wrap {
    z-index: 10000004;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 216px;
    max-width: 344px;
    margin: 0 auto;
    padding: 54px 16px 30px;
    border: 1px solid #d8dae5;
    box-sizing: border-box;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-position: center 32px;
    background-size: 16px auto;
  }
  &--success &__wrap {
    background-image: url($path + "ico_modal_success.svg");
  }
  &--warning &__wrap {
    background-image: url($path + "ico_modal_warning.svg");
  }
  &--info &__wrap {
    background-image: url($path + "ico_modal_info.svg");
  }
  &--close {
    position: absolute;
    top: 10px;
    right: 12px;
    width: 16px;
    height: 16px;
    background-image: url($path + "ico_modal_close.svg");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    text-indent: -9999px;
  }
  &__text {
    text-align: center;
    color: #484948;
    font-size: 14px;
  }
}
</style>
